.secondaryButton {
  color: #ffffff !important;
  background-color: #0E9C62 !important;
}
.successButton {
  color: #ffffff !important;
  background-color: #4dbd74 !important;
}
.ant-btn-primary {
  color: #fff;
  border-color: #4dbd74 !important;
  background: #4dbd74 !important;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

body {
  background-color: #f0f3f5 !important;
}

.ant-message {
  top: 50px !important;
}

.sidebar {
  background: #0E9C62 !important;
}

.sidebar .nav-link.active{
  background-color: #0E9C62 !important;
}

.sidebar .nav-link .nav-icon {
  color: #FFFFFF !important;
}

.sidebar .nav-link.active .nav-icon {
  color: #0E9C62 !important;
}

.badge-info {
  color: #23282c;
  background-color: #0E9C62 !important;
}

.sidebar .nav-link:hover {
  background: #ffffff !important;
  color: #0E9C62 !important;
}
/* .sidebar {
  background-color: #1d1d1d !important;
} */
.sidebar .nav-link .nav-icon:hover {
  display: inline-block;
  width: 1.09375rem;
  margin: 0 0.5rem 0 0;
  font-size: 0.875rem;
  color: #23282c !important;
  text-align: center;
}

.sidebar .nav-link.active .nav-icon {
  color: #fff !important;
}
.sidebar .nav-link:hover .nav-icon {
  color: #0E9C62 !important;
}
.ant-pagination-item-active {
  border-color: #0E9C62 !important;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #0E9C62 !important;
}

.app-header .navbar-brand {
  width: 200px !important;
}

.breadcrumb {
  color: #23282c !important;
}

.breadcrumb .breadcrumb-item a {
  color: #23282c !important;
}

.bg-black {
  background-color: #000 !important;
}

.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #0E9C62;
  top: -3px !important;
}

.ant-table-thead > tr > th {
  color: rgba(255, 255, 255, 1) !important;
  font-weight: 500;
  text-align: left;
  background: #23282c !important;
  border-bottom: 1px solid #f0f0f0;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
@media (min-width: 992px) {
  .brand-minimized .app-header .navbar-brand {
    width: 50px !important;
    background-color: #0E9C62;
  }

  .logout {
    display: none !important;
  }
}
